import Swiper from 'swiper';
import { Navigation, A11y } from 'swiper/modules';
import { setEqualHeights, checkForContent, mobileViewMax } from './util';

const WorkAt = (() => {
    function handleSwiper() {
        let init;

        if (window.innerWidth <= mobileViewMax) {
            init = true;
        } else {
            init = false;
        }

        if (init) {
            new Swiper('.js-work-at-slider', {
                // configure Swiper to use modules
                modules: [Navigation, A11y],
                spaceBetween: 25,
                slidesOffsetAfter: 25,
                slidesOffsetBefore: 25,
                slidesPerView: 1.3,
                navigation: {
                    nextEl: '.work-at-container__next-btn',
                    prevEl: '.work-at-container__prev-btn',
                },
                a11y: {
                    prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                    nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                    firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                    lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
                },
            });
        }
    }

    function handleEqualHeight() {
        if (window.innerWidth > mobileViewMax) {
            setEqualHeights($('.js-work-at-block-tile-content'), 4);
            setEqualHeights($('.js-work-at-block-tile-title'), 4);
            setEqualHeights($('.js-work-at-block-tile-paragraph'), 4);
        }
    }

    return {
        init() {
            handleSwiper();
            handleEqualHeight();
            checkForContent(document.querySelectorAll('.js-work-at-block-tile'));
        },
    };
})();

export default (() => {
    $(document).ready(WorkAt.init);
})();
